.lgtrn-block-header {
    font-size: 16px;
    font-weight: 700;
}

.lgtrn-vh-100 {
    min-height: 100vh !important;
    min-height: calc(var(--vh, 1vh) * 100) !important;
}

.lgtrn-bg-danger {
    background-color: #d72a20;
}

.lgtrn-bg-snow {
    background-image:  url('../assets/snow.png');
}

.lgtrn-text-danger {
    color: #d72a20;
}

.lgtrn-btn-outline-danger {
    color: #d72a20;
    border-color: #d72a20;
    background-color: transparent;
}
.lgtrn-btn-outline-danger:hover,
.lgtrn-btn-outline-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #d72a20;
    border-color: #d72a20;
}
.lgtrn-btn-outline-danger.disabled, 
.lgtrn-btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
}

.lgtrn-btn-danger {
    color: #fff;
    border-color: #d72a20;
    background-color: #d72a20;
}
.lgtrn-btn-danger:hover,
.lgtrn-btn-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #c0241c;
    border-color: #c0241c;
}
.lgtrn-btn-danger.disabled, 
.lgtrn-btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
}

.lgtrn-text-success {
    color: #12AB21;
}

.lgtrn-btn-success {
    color: #fff;
    border-color: #12AB21;
    background-color: #12AB21;
}
.lgtrn-btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}
.lgtrn-btn-success.disabled, 
.lgtrn-btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.lgtrn-btn-secondary {
    color: #fff;
    border-color: #6c757d;
    background-color: #6c757d;
}
.lgtrn-btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #5a6268;
}
.lgtrn-btn-secondary.disabled, 
.lgtrn-btn-secondary:disabled {
    color: #fff;
    background-color: #c6c6c6;
    border-color: #c6c6c6;
}

.lgtrn-btn-outline-purple {
    color: #8b5adb;
    border-color: #8b5adb;
    background-color: #fff;
}
.lgtrn-btn-outline-purple:hover,
.lgtrn-btn-outline-purple:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #b295e0;
    border-color: #8b5adb;
}
.lgtrn-btn-outline-purple.disabled, 
.lgtrn-btn-outline-purple:disabled {
    color: #b295e0;
    background-color: #fff;
}

.lgtrn-hint-purple {
    background: #8b5adb;
    box-shadow: 0px 8px 30px rgba(75, 38, 137, 0.45);
    border-radius: 20px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
}

.lgtrn-hint-purple .arrow::before,
.lgtrn-hint-purple .arrow::after {
    border-color: #8b5adb;
}

.lgtrn-btn-outline-purple:hover img {
    filter: brightness(500%);
}

.lgtrn-header {
    font-size: 16px;
    line-height: 50px;
    margin-left: 45px;
}

.lgtrn-logo {
    position: absolute;
    top: 5px;
    left: 15px;
}


.lghtr-header-links {
    float: right;
}

.lghtr-header-links > a {
    float: right;
    margin-top: 16px;
    margin-right: 23px;
    line-height: 17px;
}

.lghtr-header-links > a:first-child {
    margin-right: 0;
}

.lgtrn-help > img {
    height: 17px;
    vertical-align: top;
}

.lgtrn-email > img {
    height: 15px;
}

.lgtrn-status {
    font-size: 16px;
    font-weight: 700;
}

.lgtrn-form-group label {
    font-size: 11px;
    color: #777;
    padding-top: 12px;
    padding-bottom: 2px;
}

.lgtrn-form-group .form-control {
    border: none;
    font-size: 14px;
}

.lgtrn-form-group .form-control:focus {
    box-shadow: none;
}

.lgtrn-form-control {
    border-radius: 0;
    border-width: 2px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 0;
    padding-right: 0;
}

.lgtrn-form-control:focus {
    box-shadow: none;
}

.lgtrn-form-control.success, .lgtrn-form-control.success:focus {
    border-color: #12ab21;
}

.lgtrn-form-control.danger, .lgtrn-form-control.danger:focus {
    border-color: #d72a20;
}

.lgtrn-form-control-helper {
    font-size: 12px;
    min-height: 28px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.lgtrn-form-control-helper.success {
    color: #12ab21;
}

.lgtrn-form-control-helper.danger {
    color: #d72a20;
}

.lgtrn-shadow {
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.16);
}

.lgtrn-card {
    border-radius: 15px;
}

.lgtrn-card-header {
    font-size: 11px;
}
.lgtrn-card-header span {
    line-height: 14px;
    vertical-align: middle;
    padding-left: 7px;
}

.lgtrn-card-info {
    font-size: 13px;
    font-weight: 700;
}

.lgtrn-btn {
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    letter-spacing: 0.02em;
}

.lgtrn-btn-lsp-wide {
    letter-spacing: 0.04em;
}

.lgtrn-btn:focus, .lgtrn-btn.focus {
    box-shadow: none;
}

.lgtrn-btn.btn-link {
    color: #d72a20;
    padding: 0;
}

.lgtrn-btn.btn-link img + span {
    margin-left: 10px;
    border-bottom: 1px dotted #d72a20;
}

.lgtrn-btn.btn-link:hover {
    text-decoration: none;
}

.lgtrn-message {
    font-size: 14px;
    font-weight: 700;
}

.lgtrn-message-lite {
    font-size: 11px;
}

.lgtrn-hover {
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 300;
}

.lgtrn-call-info {
    font-size: 11px;
    padding-right: 20px;
    color: #777;
}

.lgtrn-call-icon a {
    display: block;
    width: 40px;
    height: 40px;
    padding: 7px 10px;
    border-radius: 50%;
    background-color: #12AB21;
}

.lgtrn-info-label-1 {
    font-size: 11px;
    padding-top: 6px;
}
.lgtrn-info-text-1 {
    font-size: 13px;
}

.lgtrn-info-label-2 {
    font-size: 11px;
    padding-top: 3px;
}
.lgtrn-info-text-2 {
    font-size: 13px;
}

.lgtrn-attention {
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    background-color: #ffefef;
    font-size: 12px;
    color: #d72a20;
}

.lgtrn-stage-title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 100px;
    margin-bottom: 25px;
}

.lgtrn-stage-title.m-sm {
    margin-top: 50px;
}

.lgtrn-stage-description {
    font-size: 14px;
}

.lgtrn-attention.no-border {
    background-color: #fff;
    padding-left: 0;
    line-height: 15px;
}

.border-radius-top {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.border-radius-bottom {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.border-radius-top-20 {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.border-radius-bottom-20 {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.color-purple {
    color: #8b5adb;
}

.arrow {
    position: absolute;
    left: 50%;
    z-index: 1055;
}

.arrow::before, .arrow::after {
    position: absolute;
    height: 7px;
    width: 7px;
    content: '';
    background: transparent;
}
.arrow::before {
    left: 0;
    border-left: 7px solid #fff;
}
.arrow::after {
    right: 0;
    border-right: 7px solid #fff;
}
.arrow.down {
    bottom: 0;
}
.arrow.down::before, .arrow.down::after {
    border-bottom: 7px solid transparent;
}

.hint-shadow {
    box-shadow: 0px 0px 7px 3px rgb(0 0 0 / 28%);
}

.hint-shadow-purple {
    box-shadow: 0px 8px 30px rgba(75, 38, 137, 0.45);
}

.lgtrn-table {
    font-size: 13px;
    color: #000;
}

.lgtrn-table thead th {
    border-top: none;
    font-weight: 400;
    border-bottom: 1px solid #dee2e6;
    font-size: 11px;
    padding-bottom: 5px;
}

.lgtrn-table th, .lgtrn-table td {
    border-bottom: 1px solid #dee2e6;
}

.lgtrn-table tr.disabled {
    color: #888;
}

.lgtrn-tr-clickable {
    cursor: pointer;
}

.lgtrn-tr-clickable.disabled {
    cursor: default;
    color: #999;
}

.lgtrn-table-cargo {
    border: none;
    font-size: 13px;
    color: #000;
}

.lgtrn-table-cargo td.number {
    text-align: center;
    vertical-align: middle;
}

.lgtrn-table-cargo thead th {
    font-weight: 400;
    font-size: 11px;
    border: none;
}

.lgtrn-company-header {
    font-size: 20px;
    font-weight: 700;
}

.font-size-11 {
    font-size: 11px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.mb-10-px {
    margin-bottom: 10px;
}

.mb-80-px {
    margin-bottom: 80px;
}

.pb-6-px {
    padding-bottom: 6px;
}

.collapsible-title {
    font-size: 13px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
}

.collapsible-title .subtitle {
    font-size: 11px;
    font-weight: 400;
}

.collapsible-title > div {
    max-width: 92%;
    padding-right: 25px;
    position: relative;
}

.collapsible-title .return-ico {
    position: absolute;
    right: 0;
    top: 5px;
}

.collapsible-title.collapsed .return-ico {
    display: none;
}

.collapsible-title:after {
    background-image: url('../assets/chevron-up.svg');
    width: 15px;
    height: 10px;
    content: '';
    display: block;
    position: absolute;
    right: 0px;
    top: 5px;
    background-repeat: no-repeat;
}

.collapsible-title.collapsed:after {
    background-image: url('../assets/chevron-down.svg');
}

.block-title {
    font-size: 13px;
    font-weight: 700;
}

.lgtrn-info-text {
    font-size: 12px;
    margin-bottom: 0;
}

.addfile-button {
    position: relative;
    overflow: hidden;
    padding: 0;
    z-index: 1;
    cursor: pointer;
    float: left;
}

.addfile-button input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    filter: 'alpha(opacity=0)';
    -ms-filter: 'alpha(opacity=0)';
    font-size: 0 !important;
    direction: ltr;
    cursor: pointer;
    text-indent: -100px;
}

.uploaded-tape {
    overflow-x: auto;
    *-ms-overflow-style: none;  /* IE and Edge */
    *scrollbar-width: none;  /* Firefox */
}
.uploaded-tape::-webkit-scrollbar {
    *display: none;
}

.uploaded-image {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    float: left;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.view-file-icon {
    position: absolute;
    top: 3px;
    right: 3px;
}

.bg-black-transparent {
    background-color: rgba(0, 0, 0, 0.16);
    color: #fff;
}
.bg-black-transparent h3 {
    font-weight: normal;
    font-size: 13px;
    line-height: 65px;
}
.bg-black-transparent .close-panel {
    color: #fff;
    line-height: 65px;
}
.bg-black-transparent .close-panel:hover {
    text-decoration: none;
    color: #eee;
}

.fill-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.order-check {
    margin-top: 3px;
}
.order-check-label {
    margin-bottom: 0;
    margin-left: 5px;
}

.mb-10 {
    margin-bottom: 5rem !important;
}

.mb-20 {
    margin-bottom: 9rem !important;
}

.lgtrn-button-like {
    display: block;
    width: 100%;
    text-align: center;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    padding: 10px 12px;
    line-height: 18px;
    border-radius: 20px;
    font-weight: 700;
}

.lgtrn-button-like-success {
    color: #12ab21;
}

.lgtrn-button-like-warning {
    color: #E97409;
}

.lgtrn-order-header {
    vertical-align: middle;
    width: 40%;
}

.lgtrn-color-success {
    color: #12AB21;
}
.lgtrn-color-warning {
    color: #E97409;
}
.lgtrn-color-danger {
    color: #D72A20;
}
.lgtrn-bg-danger-transparent {
    background-color: rgba(215, 42, 32, 0.1);
}

a.color-red {
    color: #D72A20;
}

.mb-6, .my-6 {
    margin-bottom: 4.5rem !important;
}

.mt-7, .my-7 {
    margin-top: 5rem !important;
}


.progress {
    background-color: #cbcbcb;
}
.progress-title {
    font-size: 12px;
    color: #12ab21;
    padding-bottom: 7px;
}
.progress-thin {
    height: 4px;
}

.progress-bar.bg-success {
    background-color: #12ab21;
}

.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    text-align: left;
}
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border: 1px solid #c9c9c9;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 5px;
    top: 0px;
    width: 6px;
    height: 12px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container .checkmark.white:after {
    border-color: #fff;
}

.checkbox-container div {
    padding-left: 25px;
    font-size: 13px;
}

.amount-input label {
    font-size: 11px;
}

.amount-input .form-control {
    border-radius: 0;
    height: 40px;
    border: 1px solid #B6B6B6;
    font-size: 14px;
    box-shadow: none !important;
}

.amount-input .form-control.danger {
    border-color: #f00
}

.cursor-pointer {
    cursor: pointer;
}

.w27 {
    width: 27%;
}

.side-drawer { 
    background: transparent;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
    max-height: 100vh;
    overflow: auto;
    *pointer-events: none;
}
.side-drawer.open {
    transform: translateY(0);
}

.backdrop {
    top: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1040;
}

.panel-header {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 50px;
}

.form_radio {
    margin-bottom: 20px;
}
.form_radio input[type=radio] {
	display: none;
}
.form_radio label {
	display: block;
	cursor: pointer;
	position: relative;
	padding-right: 30px;
	margin-right: 0;
	line-height: 18px;
	user-select: none;
    font-size: 13px;
    margin-bottom: 0;
}
.form_radio.left label {
    padding-left: 30px;
    padding-right: 0;
}
.form_radio input[type=radio] + label:after {
	content: "";
	display: inline-block;
	width: 18px;
	height: 18px;
	position: absolute;
	right: 0;
	top: 50%;
    margin-top: -9px;
	background: url('../assets/radio.png') 0 0 no-repeat;
}
.form_radio.left input[type=radio] + label:after {
    right: auto;
    left: 0;
}
 
/* Checked */
.form_radio input[type=radio]:checked + label:after {
	background: url('../assets/radio.png') -18px 0 no-repeat;
}
 
/* Disabled */
.form_radio input[type=radio]:disabled + label:before {
	filter: grayscale(100%);
}


.close-panel {
    color: #bbb;
    font-size: 32px;
}

.close-panel:hover {
    text-decoration: none;
    color: #777;
}
.back-panel:before {
    background: url('../assets/chevron-left.svg') no-repeat;
    content: '';
    display: block;
    float: left;
    width: 17px;
    height: 14px;
    cursor: pointer;
}

.back-panel > span {
    display: block;
    float: left;
    font-size: 10px;
    cursor: pointer;
}
